import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../../shared/services/authentication.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ResponseObject } from '../../../../../../shared/models/response-object';

@Injectable({
  providedIn: 'root'
})
export class ChangeYearService {

  endPoint = environment.endPoint + 'AccYear/';
  httpHeader = { headers: new HttpHeaders() };


  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) { }

  setHeader() {
    if ((JSON.parse(localStorage.getItem('currentUser')!)) != null) {
      this.httpHeader = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'tenantId': (JSON.parse(localStorage.getItem('currentUser')!)).tenantId,
          'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('currentUser')!)).token
        })
      };
    }
  }

  getAll(companyId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetAll?companyId=' + companyId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  getLookup(companyId): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'GetLookup?companyId=' + companyId, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  changeYear(companyId, newyear, oldYaer): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'ChangeYear?companyId=' + companyId + '&newyear=' + newyear + '&oldYaer=' + oldYaer, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }



  checkYearClosed(companyId, year): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'CheckYearClosed?companyId=' + companyId + '&year=' + year, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  addNewYear(companyId, year): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'AddNewYear?companyId=' + companyId + '&year=' + year, this.httpHeader)
      .pipe(retry(1), catchError(this.processError))
  }



  EndOfYer(companyId, year): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'EndOfYear?companyId=' + companyId + '&year=' + year, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }


  OpenYer(companyId, year): Observable<ResponseObject> {
    this.setHeader();
    return this.httpClient.get<ResponseObject>(this.endPoint + 'OpenYear?companyId=' + companyId + '&year=' + year, this.httpHeader)
      .pipe(
        retry(1),
        catchError(this.processError)
      )
  }

  processError(err: any) {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    return throwError(message);
  }
}
