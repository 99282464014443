<div class="model-full">
    <nb-card>

        <nb-card-header>
            <h6 style="float:left;color: #fc9b04">End Of Year</h6>
            <button style="float:right;" nbButton status="danger" size="medium" (click)="close(null)"><nb-icon
                    icon="close-outline"></nb-icon></button>
        </nb-card-header>

        <nb-card-body>
            <form [formGroup]="form">
                <br>
                <!-- Year form-group -->
                <div class="form-group">
                    <label for="year" class="label">Year</label>
                    <nb-select formControlName="year" fullWidth placeholder="Year"
                        (selectedChange)="yearSelectedChange($event)">
                        <nb-option *ngFor="let year of yearList" [value]="year.name">
                            {{year.name}}</nb-option>
                    </nb-select>
                </div>
                <br>

            </form>
        </nb-card-body>
        <nb-card-footer>
            <button type="submit" nbButton status="primary" size="medium" (click)="endYearClick()"
                *ngIf="selectedStatus=='Open '">End Year</button>
            <button type="submit" nbButton status="primary" size="medium" (click)="openYearClick()"
                *ngIf="selectedStatus=='Close'">Open Year</button>
        </nb-card-footer>

    </nb-card>

</div>