import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { TosterService } from '../../../../../../../shared/services/toster.service';
import { ChangeYearService } from '../../../changeYear/services/change-year.service';
import { ConfirmationComponent } from '../../../../../../../shared/components/confirmation/confirmation/confirmation.component';
import { WarningComponent } from '../../../../../../../shared/components/warning/warning.component';

@Component({
  selector: 'ngx-end-of-year',
  templateUrl: './end-of-year.component.html',
  styleUrls: ['./end-of-year.component.scss']
})
export class EndOfYearComponent implements OnInit {

  //#region  Variable

  form: FormGroup;
  yearList: [];
  selectedYear: any;
  selectedStatus: any;


  //#endregion
  constructor(private dialog: NbDialogRef<any>, private dialogService: NbDialogService, private YearService: ChangeYearService, private authenticationService: AuthenticationService, private router: Router, private tosterService: TosterService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      year: new FormControl(null),
    });
    this.getSelections();


  }

  getSelections() {
    this.YearService.getLookup(this.authenticationService.currentCompanyValue.company.id).subscribe(
      res => {
        this.yearList = res.data;
      },
      error => this.HandelingError(error)
    );

  }


  endYearClick() {
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Are you sure you want to end selected Year ?" },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) {

          this.handelingEndOfYear(this.selectedYear);

        }
      });
  }

  openYearClick() {
    this.dialogService.open(ConfirmationComponent,
      {
        context: { message: "Are you sure you want to Open selected Year ?" },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      })
      .onClose.subscribe(rep => {
        if (rep) {

          this.handelingOpenYear(this.selectedYear);

        }
      });
  }


  handelingEndOfYear(year) {
    this.YearService.EndOfYer(this.authenticationService.currentCompanyValue.company.id, year).subscribe(res => {
      if (res.code == 302) {
        this.tosterService.showToast('success', res.message);
        this.callWarning(res.message);
      } else if (res.code == 204) {
        this.tosterService.showToast('warning', res.message);
        this.callWarning(res.message);
      }
      else if (res.code == 500) {
        this.tosterService.showToast('warning', res.message);
        this.callWarning(res.message);
      }
    },
      error => this.HandelingError(error)
    );
  }


  handelingOpenYear(year) {
    this.YearService.OpenYer(this.authenticationService.currentCompanyValue.company.id, year).subscribe(res => {
      if (res.code == 302) {
        this.tosterService.showToast('success', res.message);
        this.callWarning(res.message);
      } else if (res.code == 204) {
        this.tosterService.showToast('warning', res.message);
        this.callWarning(res.message);
      }
      else if (res.code == 500) {
        this.tosterService.showToast('warning', res.message);
        this.callWarning(res.message);
      }
    },
      error => this.HandelingError(error)
    );
  }


  yearSelectedChange(event) {
    const [year, status] = event.split(" - ");
    this.selectedYear = year;
    this.selectedStatus = status;
  }


  callWarning(message) {
    this.dialogService.open(WarningComponent,
      {
        context: { message: message },
        closeOnBackdropClick: false, autoFocus: true, dialogClass: 'model-full'
      })

  }


  HandelingError(error: string) {
    if (error.includes("401") || error.includes("500") || error.includes("0")) {
      this.tosterService.showToast("warning", "Your Session Has Expired Please Login In");
      setTimeout(() => {
        this.authenticationService.Logout();
        this.router.navigate(['/sign-in']);
      }, 3000);
    }
  }

  close(data) {
    this.dialog.close(data);
  }

}
